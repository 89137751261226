html {
  font-family: 'Nunito, atten-round-new, sans-serif';
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}
